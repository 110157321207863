/* EventPost.css */
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
.event-post {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  /* background-color: red; */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100vh;
}

@media (min-width: 768px) {
  .event-post {
    flex-direction: row;
  }
}

/* Left Section: Image Carousel */
.event-post__image-section {
  flex: 2;
  position: relative;
}

.carousel {
  position: relative;
  height: 100%;
}

.carousel__image {
  display: block;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.carousel__button--left {
  left: 10px;
}

.carousel__button--right {
  right: 10px;
}

/* Right Section: Event Details */
.event-post__details-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  gap: 16px;
}

.event-post__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
}

.event-post__author {
  display: flex;
  gap: 8px;
  align-items: center;
}

.event-post__author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.event-post__author-info {
  display: flex;
  flex-direction: column;
}

.event-post__author-name {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.event-post__author-followers {
  font-size: 14px;
  font-weight: 400;
  color: #667085;
}

.event-post__description {
  font-size: 14px;
  color: #333;
}

.event-post__tags {
  font-size: 14px;
  color: #007bff;
}

.event-post__info {
  font-size: 16px;
  color: #182230;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.event-post__join-button {
  background: linear-gradient(92.71deg, #02CEFA -1.68%, #2763FF 48.87%, #A515FD 99.43%);
  color: white;
  border: none;
  font-family: "Rubik", sans-serif;
  border-radius: 15px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.event-post__join-button:hover {
  background-color: #0056b3;
}

.comment {
  background-color: #eee;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.comment_input {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  align-items: center;
  width: 100%;
}

.comment_input_bar {
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
}

.comment_input_button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 16px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.comment_input_text {
  width: 100%;
  padding: 8px;
  border-radius: 16px;
  border: 1px solid #ccc;
}

.comment_input_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* .comment p {
  margin: 0;
} */

.avatar-wrapper {
  cursor: pointer;
}

.avatar,
.reply-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.comment-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.comment-list {
  margin-bottom: 10px;
}

.comment-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-right: 20px;
  margin-top: 10px;
}

.comment-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; 
  margin-left: 10px;
  gap: 0px;
}

.username {
  font-weight: 400;
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  color: #667085;
}

.reply-to {
  font-weight: normal;
}

.comment {
  margin-bottom: 2px;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  color: #182230;
}

.reply-button {
  /* margin-top: 5px; */
  width: 100%;
}

.reply-button:hover {
  cursor: pointer;
}

.comment-time {
  font-size: 12px;
  color: #888888;
  margin-top: 2px;
  font-family: 'Rubik', sans-serif;
}

.reply-container {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  padding-right: 20px;
  margin-top: 10px;
}

.comment-like {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.like-count {
  margin-left: 5px;
  font-size: 14px;
  color: #555555;
}

.like-button {
  /* margin-left: 10px; */
  cursor: pointer;
  color: #555555;
  font-size: 12px;
}




.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with transparency */
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal content */
.modal-content {
  background: #fff;
  border-radius: 20px;
  padding: 50px;
  text-align: center;
  max-width: 400px;
  min-height: 300px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Close button */
.close-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

/* Modal heading and text */
.modal-content h2 {
  font-size: 64px;
  margin-bottom: 10px;
  color: transparent; /* Make text color transparent to show gradient */
  background: linear-gradient(97.46deg, #02cefa -10.14%, #2763ff 51.25%, #a515fd 107.69%);
  -webkit-background-clip: text; /* Clip the background to the text */
  background-clip: text; /* For non-webkit browsers */
  text-align: center; /* Center the text */
  font-style: italic;
}

.modal-content p {
  font-size: 16px;
  color: rgba(52, 64, 84, 1);
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}

/* Download buttons */
.download-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.download-buttons .btn {
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  padding: 12px 15px;
  border-radius: 15px;
  color: #fff;
  background-color: rgba(23, 92, 211, 1);
}

.btn:hover {
  opacity: 0.8;
}

.btn.play-store {
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
}